import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AmountToWord, { convertToWords } from "../../../Elements/AmountToWord";
import cookies from "../../../../../../CommonFunction/cookies";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NoticeUpdateHeader from "../../../Elements/NoticeUpdateHeader";
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from "../../../../../../API/Bank/NoticeServe";
import { showFailed, showWarning } from "../../../../../../CommonFunction/SweetAlert";
import { NoticeLoading, ServeLoading } from "../../../Elements/ServeLoading";
import { LoanByID } from "../../../../../../API/Bank/LoanSearch";
import toast from "react-hot-toast";

const Arbitration = ({ loanId, onCloseNotice, noticeStatus, envId }) => {
  const [loading, setLoading] = useState(false);
  const pdfRef = useRef();
  const currentDate = new Date();
  const [validationErrors, setValidationErrors] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const formatedDate = format(currentDate, "MMMM dd, yyyy");
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  const [noticeDetail, setNoticeDetail] = useState({
    reservePrice: "",
    authOfficerName: "",
    authOfficerDesignation: "",
    authOfficerContact: "",
    additionalText: "",
  });
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState([]);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        const data = await LoanByID(jwtToken, loanId);
        setFormData(data);
        setFetchLoading(false)
      } catch (error) {
        setFetchLoading(false)
        showFailed('Failed.', `${error.message}`);
      }
    };
    fetchData();
  }, [jwtToken]);

  const handleNoticeChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const subjectText = `Request for Consent under Rule 9(2) of the Security Interest (Enforcement) Rules, 2002 – Loan Account Number – ${formData?.identity_number} for an Amount of Rs. ${formData?.amount}  (${convertToWords(formData?.amount)}).`;

  const generatePdfAndSend = async () => {
    setLoading(true);
    const content = pdfRef.current;

    try {
      const canvas = await html2canvas(content, {
        scale: 1,
        useCORS: true,
        logging: true,
        scrollY: -window.scrollY,
        width: content.offsetWidth,
        height: content.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/png");

      const mmToPx = 72 / 25.4;
      const padding = 20;
      const pageWidth = 210;
      const pageHeight = 297; 
      const imgWidth = pageWidth - 2 * padding;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let scaledHeight = imgHeight;
      let scaledWidth = imgWidth;
      if (imgHeight + 2 * padding > pageHeight) {
        scaledHeight = pageHeight - 2 * padding;
        scaledWidth = (canvas.width * scaledHeight) / canvas.height;
      }

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidthWithPadding = pageWidth - 2 * padding;
      const imgHeightWithPadding = pageHeight - 2 * padding;
      const canvasAspectRatio = canvas.width / canvas.height;
      let effectiveImgHeight = imgWidthWithPadding / canvasAspectRatio;
      if (effectiveImgHeight > imgHeightWithPadding) {
        effectiveImgHeight = imgHeightWithPadding;
        scaledWidth = effectiveImgHeight * canvasAspectRatio;
      } else {
        scaledWidth = imgWidthWithPadding;
      }
      const uploadPromises = envId?.envelope?.map(async (item) => {
        const pdfCopy = new jsPDF("p", "mm", "a4");
        pdfCopy.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          effectiveImgHeight
        );

        pdfCopy.setFontSize(10);
        const text = `Envelope ID: ${item.envelope_id}`;
        const textWidth = pdfCopy.getTextWidth(text);
        const xCoordinate = (pageWidth - textWidth) / 2;
        pdfCopy.text(text, xCoordinate, padding);

        const pdfBlob = pdfCopy.output("blob");
        const formData1 = new FormData();
        formData1.append("file", pdfBlob, `document_${item.envelope_id}.pdf`);

        const response = await UploadNotice(jwtToken, formData1, loanId);
        return {
          link: response[0],
          userid: item.user,
          organization_id: item.organization,
          category: item.category,
        };
      });

      const responses = await Promise.all(uploadPromises);
      const sourceArray = responses.map((response) => ({
        link: response.link,
        userid: response.userid || '',
        organization_id: response.organization_id || '',
        role: response.category,
      }));

      const noticeData = {
        source: sourceArray,
        subject: subjectText,
        loanId: loanId,
        type: "arbitration",
        envelope_id: envId?._id
      };

      // Serve the notices
      const serveNoticeResponse = await ServeNotice(jwtToken, noticeData);
      const statusPayload = {
        loanId: loanId,
        type: 'arbitration',
        status: 'sent',
        bank_id: formData?.bank?._id
      };

      // Update status
      const statusUpdateResponse = await NoticeStatusUpdate(jwtToken, statusPayload);
      navigate("/banking/bankDashboard/serviceNotice/serveNoticeSuccess");
      toast.success(`Notice has been sent successfully.`);
    } catch (error) {
      console.error("Error in generatePdfAndSend:", error);
      showFailed('Operation Failed', `${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const requiredFields = [
      "authOfficerName",
      "authOfficerDesignation",
      "authOfficerContact"
    ];
    requiredFields.forEach((field) => {
      if (!noticeDetail[field]) {
        errors[field] = true;
      }
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setPreviewOpen(true);
    } else {
      showWarning('Warning...', 'Please fill the required field first.');
    }
  };

  const handleEdit = () => {
    setPreviewOpen(false);
  };
  const position = formData?.positions?.find(position => position?.role === 'borrower');
  const borrowerPosition = formData.positions?.find(
    (position) => position.role === "borrower"
  );

  // Attempt to access user.phone_number first, if not available then fallback to organization.phone_number
  const phoneNumber =
    position?.user?.phone_number ?? position?.organization?.phone_number;
  return (
    <>
      {!isPreviewOpen ? (
        <div className="mb-14">
          {fetchLoading ? (
            <NoticeLoading />
          ) : (
            <div>
              <NoticeUpdateHeader onPreview={(e) => handleSubmit(e)} onClose={onCloseNotice} />
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="bg-txtclr w-[838px] mx-auto pt-40 pb-20 px-20">
                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        {formData?.identity_number}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Notice dated
                      <span className="font-semibold font-sans">
                        ({noticeStatus?.consent?.created_at
                          ? formatDate(noticeStatus?.consent?.created_at)
                          : "N/A"})
                      </span>{" "}
                      for the recovery of the loan Amount Rs.{formData.amount}
                      {/* <span className="font-semibold font-sans">{loanId}</span>. */}
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">{subjectText}</h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {formData.user?.[0] &&
                        `${capitalizeFirstLetter(
                          formData.user[0]?.first_name || ""
                        )} ${capitalizeFirstLetter(
                          formData.user[0]?.last_name || ""
                        )}`}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-8 pl-5 mt-8">
                      <li className="pb-5">
                        Pursuant to the Security Interest (Enforcement) Rules, 2002,
                        specifically Rule 9(2), the lender is required to obtain the
                        borrower's consent before selling the immovable property below
                        the reserve price that has been pledged as security interest.
                      </li>
                      <li className="pb-5">
                        1. We,{" "}
                        <span className="text-blue-500"> {formData?.bank?.name}</span>
                        , having its registered office at
                        <span className="text-blue-500">
                          {formData?.bank?.address?.address}
                        </span>{" "}
                        and is engaged inter-alia in the business of rendering
                        finance/loan facilities to the intending borrowers, primarily
                        against the security of asset.
                        <span className="text-blue-500">
                          {formData?.collateral?.detail}.
                        </span>
                      </li>
                      <li className="pb-5">
                        2. You had approached{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>,
                        requesting the grant of a finance loan to the tune of {formData.amount} <AmountToWord amount={formData.amount} /> (“Loan/Facility”) Wherein the
                        Addressee 2 &amp; 3 stood as guarantor and mortgagor.
                        (Hereinafter all the above-named Addressees be collectively
                        referred as “borrowers”) below the reserve price of /- to
                        recover the outstanding dues under the terms of the loan
                        agreement.
                      </li>
                      <li className="pb-5">
                        3. Pursuant to your specific representation inter-alia with
                        respect to adherence of the terms of repayment of the said
                        loan, {formData?.bank?.name} has sanctioned a Loan vide
                        Sanction Letter dated (Date of the Sanction Letter) in favour
                        of you the above named borrowers vide Loan Account Number
                        (Loan Account Number). Therefore, a Loan Agreement cum
                        Mortgage Deed dated{" "}
                        <input
                          type="text"
                          name="authOfficerName"
                          id="authOfficerName"
                          value={noticeDetail.authOfficerName}
                          onChange={handleNoticeChange}
                          placeholder="[Authorized Officer's Name]"
                          className={`px-1 w-[45%] text-center border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          required
                        />{" "}
                        (“Loan Agreement”) and other loan related documents were
                        executed to inter-alia create registered mortgage in respect
                        of development rights over the properties as mentioned below
                        in favour of{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        against the said Loan/facility.
                      </li>
                      <li className="pb-5">
                        4. That for securing the above-mentioned loan, you the
                        Addressees No. 2 &amp; 3, executed Guarantee Agreement dated
                        XXXXXXX in your capacity as the guarantors and mortgagor
                        thereby guaranteeing joint and/or several personal guarantees
                        for securing the due repayment of the above-mentioned Loan
                        along with interest and other charges payable thereon.
                      </li>
                      <li className="pb-5">
                        5. The Assets over which security has been created in favour
                        of{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        are hereinafter` collectively referred to as “Secured Asset”.
                      </li>
                      <li className="pb-5">
                        6. We hereby inform you that you the above-named addressee
                        have committed breach of the terms and conditions of the said
                        Loan Agreement and other documents executed in relation
                        thereto by inter alia defaulting in payment of instalments due
                        and payable by you to{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>,
                        under the said Loan Agreement. Despite repeated requests
                        calling upon you to repay the interest due, all of you and
                        each of you who are jointly and severally liable, have failed
                        to repay the outstanding dues.
                      </li>
                      <li className="pb-5">
                        7. As the Equated Monthly Instalments of the aforesaid Loan
                        Accounts has remained overdue, your Account has been
                        classified as a &quot;Non-Performing Asset (NPA)&quot; on
                        (Date of being declared Non performing Asset) in the books of
                        accounts maintained by{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        in accordance with the directives and guidelines relating to
                        asset classification, issued by the National Housing Bank/
                        Reserve Bank of India, from time to time.
                      </li>
                      <li className="pb-5">
                        8. We hereby inform you that, total amount outstanding and
                        payable to{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        by you the addressees are Rs. (Total Amount Outstanding) in
                        respect of the aforesaid Facility. The details of the said
                        dues are more specifically mentioned in Schedule-l appearing
                        herein under.
                      </li>
                      <li className="pb-5">
                        9. In view of the above, it is evident that disputes have
                        arisen between you and us, thus we seek to invoke the
                        arbitration clause in accordance with Clause{" "}
                        <span className="text-blue-500">(Clause Number)</span>
                        of the Loan Agreement cum Mortgage Deed dated XXXXX (“Loan
                        Agreement”). The relevant extracts of the arbitration clause
                        of the Loan Agreement cum Mortgage Deed dated XXXXX (“Loan
                        Agreement”) is reproduced herein below for the sake of
                        convenience: (The relevant extracts of the arbitration clause)
                      </li>
                      <li className="pb-5">
                        10. In view of the aforementioned facts and circumstances, we
                        hereby appoint a panel of the following three (03)
                        Arbitrators, out of which you may select one of the Arbitrator
                        to act as an impartial Sole Arbitrator in order to adjudicate
                        the disputes between you and{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        judiciously. Panel of Arbitrators: 1. Name: (Name of the
                        proposed Arbitrator) Email: (Email of the proposed Arbitrator)
                        Phone: (Phone of the proposed Arbitrator) 2. Name: (Name of
                        the proposed Arbitrator) Email: (Email of the proposed
                        Arbitrator) Phone: (Phone of the proposed Arbitrator) 3. Name:
                        (Name of the proposed Arbitrator) Email: (Email of the
                        proposed Arbitrator) Phone: (Phone of the proposed Arbitrator)
                      </li>
                      <li className="pb-5">
                        11. In view of the aforementioned facts and circumstances, you
                        are hereby called upon to forthwith fulfil the following just
                        and fair compliances - a. Confirm the receipt of this Notice
                        within 7 (seven) days of the issuance of the same; AND b.
                        Select one of the Arbitrator, from the panel of Arbitrators.
                        To act as the Sole Arbitrator, within 7 (seven) days of the
                        receipt of this Notice. ClevDoc Envelope ID: XXXXXXX Failing
                        which it shall be presumed that you have received the Notice
                        and our bank shall appoint the Arbitrator from the suggested
                        panel and request the Sole Arbitrator to enter reference and
                        commence the arbitration proceedings.
                      </li>
                      <li className="pb-5">
                        12. Please also note that this notice is sent to you without
                        prejudice to the other rights and remedies available to{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        including initiation of the appropriate legal proceedings
                        before the appropriate courts and/or tribunal for recovery of
                        the above said outstanding amount. This Notice is also without
                        prejudice to{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>
                        &#39;s right to institute further proceedings as warranted
                        under the law.
                      </li>
                      <li className="mt-10">
                        13. Any correspondence in this regard may be addressed to the
                        Authorized Officer at the above-mentioned Registered Office at
                        New Delhi.
                      </li>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <input
                        type="text"
                        name="authOfficerName"
                        id="authOfficerName"
                        value={noticeDetail.authOfficerName}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Name]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="authOfficerDesignation"
                        id="authOfficerDesignation"
                        value={noticeDetail.authOfficerDesignation}
                        onChange={handleNoticeChange}
                        required
                        placeholder="[Authorized Officer's Designation]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        name="authOfficerContact"
                        id="authOfficerContact"
                        value={noticeDetail.authOfficerContact}
                        onChange={handleNoticeChange}
                        placeholder="[Contact Details of the Authorized Officer]"
                        className={`px-1 w-[55%] border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-20">
                    <button
                      type="submit"
                      className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="font-serif py-10">
          {loading ? (
            <ServeLoading />
          ) : (
            <div className="w-[860px] mx-auto">
              <div className="bg-txtclr py-10 pb-28 px-20">
                <div className="flex justify-end text-gray-500 mt-5 mb-3">
                  <button
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                    className="text-[15px] px-2 text-txtclr bg-black font-sans rounded-sm"
                  >
                    X
                  </button>
                </div>
                <div ref={pdfRef} className="p-4">

                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        {formData?.identity_number}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Loan against Account Number{" "}
                      <span className="font-semibold font-sans">
                        {/* {loanId} */}
                        233542346
                      </span>{" "}
                      for an Amount of Rs.{" "}
                      <span className="font-semibold font-sans">
                        {formData?.amount} <AmountToWord amount={formData?.amount} />
                      </span>
                      .
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">{subjectText}</h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {formData.user?.[0] &&
                        `${capitalizeFirstLetter(
                          formData.user[0]?.first_name || ""
                        )} ${capitalizeFirstLetter(
                          formData.user[0]?.last_name || ""
                        )}`}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-8 pl-5 mt-8">
                      <li className="pb-5">
                        Pursuant to the Security Interest (Enforcement) Rules,
                        2002, specifically Rule 9(2), the lender is required
                        to obtain the borrower's consent before selling the
                        immovable property below the reserve price that has
                        been pledged as security interest.
                      </li>
                      <li className="pb-5">
                        Following the enforcement process and in compliance
                        with Rule 9(2) of the Security Interest (Enforcement)
                        Rules, 2002, our authorized officer has conducted the
                        sale proceedings. However, regrettably, despite our
                        efforts, the authorized officer was unable to obtain a
                        bid higher than the reserve price set for the
                        immovable property, that is{" "}
                        <span className="text-blue-500">
                          {formData?.collateral?.detail}.
                        </span>
                      </li>
                      <li className="pb-5">
                        In light of the above, we seek your formal consent to
                        proceed with the sale of the aforementioned immovable
                        property, that is{" "}
                        <span className="text-blue-500">
                          {formData?.collateral?.detail}.
                        </span>{" "}
                        below the reserve price of{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.reservePrice} /-
                        </span>{" "}
                        <span className="font-semibold">
                          (<AmountToWord amount={noticeDetail.reservePrice} />
                          )
                        </span>{" "}
                        to recover the outstanding dues under the terms of the
                        loan agreement.
                      </li>
                      <li className="pb-5">{noticeDetail.additionalText}</li>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-16">
                  <button
                    type="submit"
                    onClick={generatePdfAndSend}
                    className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Arbitration;
