import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AmountToWord, { convertToWords } from "../../../Elements/AmountToWord";
import cookies from "../../../../../../CommonFunction/cookies";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NoticeUpdateHeader from "../../../Elements/NoticeUpdateHeader";
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from "../../../../../../API/Bank/NoticeServe";
import { showFailed, showWarning } from "../../../../../../CommonFunction/SweetAlert";
import { NoticeLoading, ServeLoading } from "../../../Elements/ServeLoading";
import { LoanByID } from "../../../../../../API/Bank/LoanSearch";
import toast from "react-hot-toast";

const SaleNotice = ({ loanId, onCloseNotice, noticeStatus, envId }) => {
  const [loading, setLoading] = useState(false);
  const pdfRef = useRef();
  const currentDate = new Date();
  const [validationErrors, setValidationErrors] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const formatedDate = format(currentDate, "MMMM dd, yyyy");
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  const [noticeDetail, setNoticeDetail] = useState({
    borrowerName: "",
    reservePrice: "",
    authOfficerName: "",
    authOfficerDesignation: "",
    authOfficerContact: "",
    additionalText: "",
  });
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months start at 0
    const year = date.getFullYear();

    // Format day and month to ensure they are displayed as two digits
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        const data = await LoanByID(jwtToken, loanId);
        setFormData(data);
        setFetchLoading(false)
      } catch (error) {
        setFetchLoading(false)
        showFailed('Failed.', `${error.message}`);
      }
    };
    fetchData();
  }, [jwtToken]);

  const handleNoticeChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const subjectText = `Sale Notice under SARFAESI Act, 2002 - ${formData?.identity_number} –      
  XXXXXXX) for an Amount of Rs. ${formData.amount} (${convertToWords(formData?.amount)})`;

  const generatePdfAndSend = async () => {
    setLoading(true);
    const content = pdfRef.current;

    try {
      const canvas = await html2canvas(content, {
        scale: 1,
        useCORS: true,
        logging: true,
        scrollY: -window.scrollY,
        width: content.offsetWidth,
        height: content.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/png");

      const mmToPx = 72 / 25.4;
      const padding = 20; // mm
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgWidth = pageWidth - 2 * padding; // Image width with padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image height to maintain aspect ratio

      let scaledHeight = imgHeight;
      let scaledWidth = imgWidth;
      if (imgHeight + 2 * padding > pageHeight) {
        scaledHeight = pageHeight - 2 * padding;
        scaledWidth = (canvas.width * scaledHeight) / canvas.height;
      }

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidthWithPadding = pageWidth - 2 * padding;
      const imgHeightWithPadding = pageHeight - 2 * padding;
      const canvasAspectRatio = canvas.width / canvas.height;
      let effectiveImgHeight = imgWidthWithPadding / canvasAspectRatio;
      if (effectiveImgHeight > imgHeightWithPadding) {
        effectiveImgHeight = imgHeightWithPadding;
        scaledWidth = effectiveImgHeight * canvasAspectRatio;
      } else {
        scaledWidth = imgWidthWithPadding;
      }

      
      const uploadPromises = envId?.envelope?.map(async (item) => {
        const pdfCopy = new jsPDF("p", "mm", "a4");
        pdfCopy.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          effectiveImgHeight
        );

        pdfCopy.setFontSize(10);
        const text = `Envelope ID: ${item.envelope_id}`;
        const textWidth = pdfCopy.getTextWidth(text);
        const xCoordinate = (pageWidth - textWidth) / 2;
        pdfCopy.text(text, xCoordinate, padding);

        const pdfBlob = pdfCopy.output("blob");
        const formData1 = new FormData();
        formData1.append("file", pdfBlob, `document_${item.envelope_id}.pdf`);

        const response = await UploadNotice(jwtToken, formData1, loanId);
        

        return {
          link: response[0],
          userid: item.user,
          organization_id: item.organization,
          category: item.category,
        };
      });

      const responses = await Promise.all(uploadPromises);
      const sourceArray = responses.map((response) => ({
        link: response.link,
        userid: response.userid || '',
        organization_id: response.organization_id || '',
        role: response.category,
      }));

      const noticeData = {
        source: sourceArray,
        subject: subjectText,
        loanId: loanId,
        type: "sale",
        envelope_id: envId?._id
      };

      // Serve the notices
      const serveNoticeResponse = await ServeNotice(jwtToken, noticeData);
      

      const statusPayload = {
        loanId: loanId,
        type: 'sale',
        status: 'sent',
        bank_id: formData?.bank?._id
      };

      // Update status
      const statusUpdateResponse = await NoticeStatusUpdate(jwtToken, statusPayload);
      navigate("/banking/bankDashboard/serviceNotice/serveNoticeSuccess");
      toast.success(`Notice has been sent successfully.`);
    } catch (error) {
      console.error("Error in generatePdfAndSend:", error);
      showFailed('Operation Failed', `${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const requiredFields = [
      "auctionPlace",
      "demandAmount",
      "saleDate",
      "websiteName",
      "payDays",
      "saleDueAmount",
      "soldDays",
      "possessionDate",
      "saleDate",
      "authOfficerName",
      "authOfficerDesignation",
      "authOfficerContact"
    ];
    requiredFields.forEach((field) => {
      if (!noticeDetail[field]) {
        errors[field] = true;
      }
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setPreviewOpen(true);
      
    } else {
      showWarning('Warning...', 'Please fill the required field first.');
    }
  };

  const handleEdit = () => {
    setPreviewOpen(false);
  };
  const borrowerPosition = formData?.positions?.find(
    (position) => position.role === "borrower"
  );
  const position = formData?.positions?.find(
    (position) => position.role === "borrower"
  );

  // Attempt to access user.phone_number first, if not available then fallback to organization.phone_number
  const phoneNumber =
    position?.user?.phone_number ?? position?.organization?.phone_number;

  if (phoneNumber) {
    
  } else {
    
  }
  return (
    <>
      {!isPreviewOpen ? (
        <div className="mb-14">
          {fetchLoading ? (
            <NoticeLoading />
          ) : (
            <div>
              <NoticeUpdateHeader onPreview={(e) => handleSubmit(e)} onClose={onCloseNotice} />
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="bg-txtclr w-[838px] mx-auto pt-40 pb-20 px-20">
                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg underline border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Demand Notice dated (
                      <span className="font-semibold text-blue-500">
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus?.demand?.created_at)
                          : "N/A"} ,{" "}
                      </span>{" "}
                      bearing Reference Number –
                      <span className="text-blue-500 font-semibold"> XXXXX)</span> and
                      Possession Notice dated

                      (<span className="text-blue-500 font-semibold">{noticeStatus?.posession?.created_at
                        ? formatDate(noticeStatus.posession.created_at)
                        : "N/A"}</span>{" "}
                      bearing Reference Number -{" "}
                      <span className="text-blue-500 font-semibold">XXXX</span>)
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Sale Notice under SARFAESI Act, 2002 - Loan Account Number –{" "}
                      {formData.identity_number} for an Amount of Rs.{" "}
                      {formData.amount} (<AmountToWord amount={formData?.amount} />)
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        The undersigned being the Authorised Officer of{" "}
                        <span className="text-blue-500">{formData?.bank?.name},</span>{" "}
                        give notice to you as under;
                      </li>
                      <li className="pb-5">
                        The undersigned as an Authorised Officer of{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        in exercise of powers conferred under Section 13 (12) of the
                        Securitisation and Reconstruction of Financial Assets and
                        Enforcement of Security Interest Act 2002 (the Act), issued
                        Demand Notice under Section 13 (2) of the Act read with Rule 3
                        of Security Interest (Enforcement) Rules 2002 (the Rules)
                        dated{" "}
                        <span className="text-blue-500">
                          ( {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus?.demand?.created_at)
                            : "N/A"}){" "}
                        </span>
                        to you to repay the amount mentioned in the Notice being{" "}
                        <input
                          type="text"
                          required
                          placeholder="Amount demanded in the Demand Notice"
                          name="demandAmount"
                          id="demandAmount"
                          value={noticeDetail.demandAmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.demandAmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        /- towards the outstanding dues in respect of the Loans,
                        together with further interest thereon within 60 days from the
                        date of the said Notice.
                      </li>
                      <li className="pb-5">
                        As you failed to repay the aforesaid amount, the undersigned
                        in exercise of powers conferred under Section 13 (4) of the
                        Act read with the Rules 8(1) of the Rules, took over the
                        physical possession of the immovable secured assets of the
                        Borrower situated at{" "}
                        <span className="text-blue-500">
                          {formData?.collateral?.detail},
                        </span>{" "}
                        on{" "}
                        <input
                          type="date"
                          required
                          placeholder="DD/MM/YYYY"
                          name="possessionDate"
                          id="possessionDate"
                          value={noticeDetail.possessionDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.possessionDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        the details of immovable secured assets are mentioned in
                        Annexure hereto. The undersigned has issued the Possession
                        Notice in compliance of Rule 8 (1) and Rule 8 (2) of the
                        Rules.
                      </li>
                      <li className="pb-5">
                        Pursuant to taking over possession of the immovable secured
                        assets of the Borrower, the undersigned propose to sell them.
                        Accordingly, Notice is hereby given to you under Rules 9 (1)
                        of the Rules that the above mentioned immovable secured asset
                        shall be sold after{" "}
                        <input
                          type="text"
                          required
                          placeholder="Enter Days"
                          name="soldDays"
                          id="soldDays"
                          value={noticeDetail.soldDays}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.soldDays ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        days on{" "}
                        <input
                          type="date"
                          required
                          placeholder="DD/MM/YYYY"
                          name="saleDate"
                          id="saleDate"
                          value={noticeDetail.saleDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.saleDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        from the date of this notice by adopting any of the following
                        methods mentioned in Rule 8 (5) of the Rules:
                        <ul className="list-disc list-outside ml-20 mt-3">
                          <li className="pb-5">
                            by obtaining quotations from the parties dealing in the
                            secured assets or otherwise interested in buying such
                            assets; or
                          </li>
                          <li className="pb-5">
                            by inviting tenders from the public; or
                          </li>
                          <li className="pb-5">
                            by holding public auction including through e-auction
                            mode; or{" "}
                          </li>
                          <li className="pb-5">by private treaty.</li>
                        </ul>
                      </li>
                      <li className="pb-5">
                        However, you may pay the entire outstanding dues of{" "}
                        <input
                          type="text"
                          required
                          placeholder="Total sale due amount"
                          name="saleDueAmount"
                          id="saleDueAmount"
                          value={noticeDetail.saleDueAmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-44 text-center border-b ${validationErrors.saleDueAmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        together with further interest and take back the possession of
                        the immovable secured assets in question within the time limit
                        specified herein above.
                      </li>
                      <li className="pb-5">
                        In case you fail to pay the outstanding dues as demanded,
                        within{" "}
                        <input
                          type="text"
                          required
                          placeholder="Enter Days"
                          name="payDays"
                          id="payDays"
                          value={noticeDetail.payDays}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.payDays ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        from the date of this Notice, the undersigned would be at
                        liberty to proceed with the sale of the immovable secured
                        assets by adopting any of the aforesaid methods as may be
                        required in the best interest of{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>
                      </li>
                      <div className="mt-10">
                        <textarea
                          className="border border-gray-500 p-3"
                          name="additionalText"
                          value={noticeDetail.additionalText}
                          onChange={handleNoticeChange}
                          placeholder="You can write your additional content here............"
                          id="additionalText"
                          cols="80"
                          rows="6"
                        ></textarea>
                      </div>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerName"
                        id="authOfficerName"
                        value={noticeDetail.authOfficerName}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Name]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerDesignation"
                        id="authOfficerDesignation"
                        value={noticeDetail.authOfficerDesignation}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Designation]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerContact"
                        id="authOfficerContact"
                        value={noticeDetail.authOfficerContact}
                        onChange={handleNoticeChange}
                        placeholder="[Contact Details of the Authorized Officer]"
                        className={`px-1 w-[55%] border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                  </div>
                  <div className="bg-txtclr pt-10 pb-28">
                    <div className="flex justify-center mb-10">
                      <h2 className="text-lg underline border-gray-800 font-bold">
                        (Short description of the immovable property)
                      </h2>
                    </div>
                    <div>
                      <ul className="list-disc leading-6 pl-5 mt-8">
                        <li className="pb-5">
                          For detailed terms and conditions of the sale, please refer to
                          the link provided in{" "}
                          <span className="text-blue-500">
                            {formData?.bank?.name},{" "}
                          </span>
                          the Secured Creditor’s website:{" "}
                          <input
                            type="text"
                            required
                            placeholder="https://clevdoc.com"
                            name="websiteName"
                            id="websiteName"
                            value={noticeDetail.websiteName}
                            onChange={handleNoticeChange}
                            className={`px-1 w-[45%] border-b ${validationErrors.websiteName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          />
                        </li>
                        <li>
                          Date:{" "}
                          <input
                            type="date"
                            placeholder="Sale Date"
                            name="saleDate"
                            id="saleDate"
                            value={noticeDetail.saleDate}
                            onChange={handleNoticeChange}
                            className={`px-1 w-[45%] border-b ${validationErrors.saleDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          />
                        </li>
                        <li>
                          Place:{" "}
                          <span>
                            {" "}
                            <input
                              type="text"
                              required
                              placeholder="Place Of Auction"
                              name="auctionPlace"
                              id="auctionPlace"
                              value={noticeDetail.auctionPlace}
                              onChange={handleNoticeChange}
                              className={`px-1 w-[45%] border-b ${validationErrors.auctionPlace ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                            />
                          </span>
                        </li>
                        <li className="mt-10">Dated: {formatedDate}</li>
                        <li>
                          Place:{" "}
                          <span className="text-blue-500"> (Address Of the bank)</span>
                        </li>
                      </ul>
                    </div>
                    <div className="text-right font-semibold mt-20">
                      <div>
                        <input
                          type="text"
                          required
                          name="authOfficerName"
                          id="authOfficerName"
                          value={noticeDetail.authOfficerName}
                          onChange={handleNoticeChange}
                          placeholder="[Authorized Officer's Name]"
                          className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          required
                          name="authOfficerDesignation"
                          id="authOfficerDesignation"
                          value={noticeDetail.authOfficerDesignation}
                          onChange={handleNoticeChange}
                          placeholder="[Authorized Officer's Designation]"
                          className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                      </div>
                      <div>
                        <h2>
                          For,{" "}
                          <span className="text-blue-500">{formData?.bank?.name}</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-7">
                    <button
                      type="submit"
                      className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="font-serif py-10">
          {loading ? (
            <ServeLoading />
          ) : (
            <div className="w-[860px] mx-auto">
              <div className="bg-txtclr py-10 pb-28 px-20">
                <div className="flex justify-end text-gray-500 mt-5 mb-3">
                  <button
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                    className="text-[15px] px-2 text-txtclr bg-black font-sans rounded-sm"
                  >
                    X
                  </button>
                </div>
                <div ref={pdfRef} className="p-4">

                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Demand Notice dated (
                      <span className="font-semibold text-blue-500">
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus?.demand?.created_at)
                          : "N/A"},{" "}
                      </span>{" "}
                      bearing Reference Number –
                      <span className="text-blue-500 font-semibold">
                        {" "}
                        XXXXX)
                      </span>{" "}
                      and Possession Notice dated{" "}
                      <span className="text-blue-500 font-semibold">
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus?.posession?.created_at)
                          : "N/A"}
                      </span>{" "}
                      bearing Reference Number -{" "}
                      <span className="text-blue-500 font-semibold">
                        XXXX
                      </span>
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Sale Notice under SARFAESI Act, 2002 - Loan Account
                      Number – {formData.identity_number} for an Amount of Rs.{" "}
                      {formData.amount} (<AmountToWord amount={formData?.amount} />)
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        The undersigned being the Authorised Officer of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},
                        </span>{" "}
                        give notice to you as under;
                      </li>
                      <li className="pb-5">
                        The undersigned as an Authorised Officer of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        in exercise of powers conferred under Section 13 (12)
                        of the Securitisation and Reconstruction of Financial
                        Assets and Enforcement of Security Interest Act 2002
                        (the Act), issued Demand Notice under Section 13 (2)
                        of the Act read with Rule 3 of Security Interest
                        (Enforcement) Rules 2002 (the Rules) dated{" "}
                        <span className="text-blue-500">
                          ( {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus?.demand?.created_at)
                            : "N/A"}){" "}
                        </span>
                        to you to repay the amount mentioned in the Notice
                        being{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.demandAmount} /-
                        </span>{" "}
                        <span className="font-semibold">
                          (<AmountToWord amount={noticeDetail.demandAmount} />
                          )
                        </span>{" "}
                        towards the outstanding dues in respect of the Loans,
                        together with further interest thereon within 60 days
                        from the date of the said Notice.
                      </li>
                      <li className="pb-5">
                        As you failed to repay the aforesaid amount, the
                        undersigned in exercise of powers conferred under
                        Section 13 (4) of the Act read with the Rules 8(1) of
                        the Rules, took over the physical possession of the
                        immovable secured assets of the Borrower situated at{" "}
                        <span className="text-blue-500">
                          {formData?.collateral?.detail},
                        </span>{" "}
                        on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.possessionDate}
                        </span>{" "}
                        the details of immovable secured assets are mentioned
                        in Annexure hereto. The undersigned has issued the
                        Possession Notice in compliance of Rule 8 (1) and Rule
                        8 (2) of the Rules.
                      </li>
                      <li className="pb-5">
                        Pursuant to taking over possession of the immovable
                        secured assets of the Borrower, the undersigned
                        propose to sell them. Accordingly, Notice is hereby
                        given to you under Rules 9 (1) of the Rules that the
                        above mentioned immovable secured asset shall be sold
                        after{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.soldDays}
                        </span>{" "}
                        days on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.saleDate}
                        </span>{" "}
                        from the date of this notice by adopting any of the
                        following methods mentioned in Rule 8 (5) of the
                        Rules:
                        <ul className="list-disc list-outside ml-20 mt-3">
                          <li className="pb-5">
                            by obtaining quotations from the parties dealing
                            in the secured assets or otherwise interested in
                            buying such assets; or
                          </li>
                          <li className="pb-5">
                            by inviting tenders from the public; or
                          </li>
                          <li className="pb-5">
                            by holding public auction including through
                            e-auction mode; or{" "}
                          </li>
                          <li className="pb-5">by private treaty.</li>
                        </ul>
                      </li>
                      <li className="pb-5">
                        However, you may pay the entire outstanding dues of{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.saleDueAmount} /-
                        </span>{" "}
                        <span className="font-semibold">
                          ({" "}
                          <AmountToWord amount={noticeDetail.saleDueAmount} />{" "}
                          )
                        </span>{" "}
                        together with further interest and take back the
                        possession of the immovable secured assets in question
                        within the time limit specified herein above.
                      </li>
                      <li className="pb-5">
                        In case you fail to pay the outstanding dues as
                        demanded, within{" "}
                        <span className="font-semibold">
                          {noticeDetail.payDays}
                        </span>{" "}
                        from the date of this Notice, the undersigned would be
                        at liberty to proceed with the sale of the immovable
                        secured assets by adopting any of the aforesaid
                        methods as may be required in the best interest of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>
                      </li>
                      <li className="pb-5">{noticeDetail.additionalText}</li>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                  <div className="flex justify-center mb-10 mt-52">
                    <h2 className="text-lg underline border-gray-800 font-bold">
                      (Short description of the immovable property)
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        For detailed terms and conditions of the sale, please
                        refer to the link provided in{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},{" "}
                        </span>
                        the Secured Creditor’s website:{" "}
                        <Link
                          to={noticeDetail.websiteName}
                          className="text-blue-500 hover:underline"
                        >
                          {noticeDetail.websiteName}
                        </Link>
                      </li>
                      <li>
                        Date:{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.saleDate}
                        </span>
                      </li>
                      <li>
                        Place:{" "}
                        <span className="font-semibold">
                          {noticeDetail.auctionPlace}
                        </span>
                      </li>
                      <li className="mt-10">Dated: {formatedDate}</li>
                      <li>
                        Place:{" "}
                        <span className="text-blue-500">
                          {" "}
                          (Address Of the bank)
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="text-right font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2>
                        For,{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-16">
                  <button
                    type="submit"
                    onClick={generatePdfAndSend}
                    className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SaleNotice;
