import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AmountToWord from "../../../Elements/AmountToWord";
import cookies from "../../../../../../CommonFunction/cookies";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from "../../../../../../API/Bank/NoticeServe";
import { showFailed, showWarning } from "../../../../../../CommonFunction/SweetAlert";
import NoticeUpdateHeader from "../../../Elements/NoticeUpdateHeader";
import { NoticeLoading, ServeLoading } from "../../../Elements/ServeLoading";
import { LoanByID } from "../../../../../../API/Bank/LoanSearch";
import toast from "react-hot-toast";
// Define styles

const DemandNotice = ({ loanId, onCloseNotice, noticeStatus, envId }) => {
  const [loading, setLoading] = useState(false);
  const pdfRef = useRef();
  const currentDate = new Date();
  const [validationErrors, setValidationErrors] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const formatedDate = format(currentDate, "MMMM dd, yyyy");
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  const [noticeDetail, setNoticeDetail] = useState({
    borrowerName: "",
    reservePrice: "",
    authOfficerName: "",
    authOfficerDesignation: "",
    authOfficerContact: "",
    additionalText: "",
  });
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months start at 0
    const year = date.getFullYear();

    // Format day and month to ensure they are displayed as two digits
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        const data = await LoanByID(jwtToken, loanId);
        setFormData(data);
        setFetchLoading(false)
      } catch (error) {
        setFetchLoading(false)
        showFailed('Failed.', `${error.message}`);
      }
    };
    fetchData();
  }, [jwtToken]);

  const handleNoticeChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const subjectText = `Notice under Section 13(2) of the Securitization and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002 (“SARFAESI Act, 2002”) read with Security Interest (Enforcement) Rules, 2002 (“Rules”) as amended from time to time.`;

  const generatePdfAndSend = async () => {
    setLoading(true);
    const content = pdfRef.current;

    try {
      const canvas = await html2canvas(content, {
        scale: 1,
        useCORS: true,
        logging: true,
        scrollY: -window.scrollY,
        width: content.offsetWidth,
        height: content.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/png");

      const mmToPx = 72 / 25.4;
      const padding = 20; // mm
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgWidth = pageWidth - 2 * padding; // Image width with padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image height to maintain aspect ratio

      let scaledHeight = imgHeight;
      let scaledWidth = imgWidth;
      if (imgHeight + 2 * padding > pageHeight) {
        scaledHeight = pageHeight - 2 * padding;
        scaledWidth = (canvas.width * scaledHeight) / canvas.height;
      }

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidthWithPadding = pageWidth - 2 * padding;
      const imgHeightWithPadding = pageHeight - 2 * padding;
      const canvasAspectRatio = canvas.width / canvas.height;
      let effectiveImgHeight = imgWidthWithPadding / canvasAspectRatio;
      if (effectiveImgHeight > imgHeightWithPadding) {
        effectiveImgHeight = imgHeightWithPadding;
        scaledWidth = effectiveImgHeight * canvasAspectRatio;
      } else {
        scaledWidth = imgWidthWithPadding;
      }

      const uploadPromises = envId?.envelope?.map(async (item) => {
        const pdfCopy = new jsPDF("p", "mm", "a4");
        pdfCopy.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          effectiveImgHeight
        );

        pdfCopy.setFontSize(10);
        const text = `Envelope ID: ${item.envelope_id}`;
        const textWidth = pdfCopy.getTextWidth(text);
        const xCoordinate = (pageWidth - textWidth) / 2;
        pdfCopy.text(text, xCoordinate, padding);

        const pdfBlob = pdfCopy.output("blob");
        const formData1 = new FormData();
        formData1.append("file", pdfBlob, `document_${item.envelope_id}.pdf`);

        const response = await UploadNotice(jwtToken, formData1, loanId);

        return {
          link: response[0],
          userid: item.user,
          organization_id: item.organization,
          category: item.category,
        };
      });

      const responses = await Promise.all(uploadPromises);
      const sourceArray = responses.map((response) => ({
        link: response.link,
        userid: response.userid || '',
        organization_id: response.organization_id || '',
        role: response.category,
      }));

      const noticeData = {
        source: sourceArray,
        subject: subjectText,
        loanId: loanId,
        type: "sarfaesi_npa",
        envelope_id: envId?._id
      };

      // Serve the notices
      const serveNoticeResponse = await ServeNotice(jwtToken, noticeData);
      const statusPayload = {
        loanId: loanId,
        type: 'sarfaesi_npa',
        status: 'sent',
        bank_id: formData?.bank?._id
      };

      // Update status
      const statusUpdateResponse = await NoticeStatusUpdate(jwtToken, statusPayload);
      navigate("/banking/bankDashboard/serviceNotice/serveNoticeSuccess");
      toast.success(`Notice has been sent successfully.`);
    } catch (error) {
      console.error("Error in generatePdfAndSend:", error);
      showFailed('Operation Failed', `${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const requiredFields = [
      "noticeDate",
      "totalDueAmount",
      "demandTotalAmount",
      "nonPerformingDate",
      "sanctionDate",
      "authOfficerName",
      "authOfficerDesignation",
      "authOfficerContact",
    ];
    requiredFields.forEach((field) => {
      if (!noticeDetail[field]) {
        errors[field] = true;
      }
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setPreviewOpen(true);
    } else {
      showWarning('Warning...', 'Please fill the required field first.');
    }
  };

  const handleEdit = () => {
    setPreviewOpen(false);
  };
  const borrowerPosition = formData.positions?.find(
    (position) => position.role === "borrower"
  );

  const position = formData?.positions?.find(
    (position) => position.role === "borrower"
  );

  // Attempt to access user.phone_number first, if not available then fallback to organization.phone_number
  const phoneNumber =
    position?.user?.phone_number ?? position?.organization?.phone_number;
  return (
    <>
      {!isPreviewOpen ? (
        <div className="mb-14">
          {fetchLoading ? (
            <NoticeLoading />
          ) : (
            <div>
              <NoticeUpdateHeader onPreview={(e) => handleSubmit(e)} onClose={onCloseNotice} />
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="bg-txtclr w-[838px] mx-auto pt-40 pb-20 px-20">
                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Reminder Notice dated (
                      <span className="font-semibold text-blue-500">
                        First Reminder Date,
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus.reminder_1.created_at)
                          : "N/A"}
                      </span>
                      bearing Reference No.:{" "}
                      <span className="text-blue-500 font-semibold"> XXXXX)</span> for
                      First Month Payment Default, Reminder dated (
                      {noticeStatus?.reminder_2?.created_at
                        ? formatDate(noticeStatus.reminder_2.created_at)
                        : "N/A"}
                      , bearing Reference No. :
                      <span className="font-semibold text-blue-500"> XXXXXX)</span>{" "}
                      for Second Month Payment Default and Reminder dated (
                      <span className="font-semibold text-blue-500">
                        {noticeStatus?.npa?.created_at
                          ? formatDate(noticeStatus.npa.created_at)
                          : "N/A"}
                        ,
                      </span>{" "}
                      bearing Reference Number.:{" "}
                      <span className="font-semibold text-blue-500">XXXXX)</span> for
                      Third Month Payment Default.
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Notice under Section 13(2) of the Securitization and
                      Reconstruction of Financial Assets and Enforcement of Security
                      Interest Act, 2002 (“SARFAESI Act, 2002”) read with Security
                      Interest (Enforcement) Rules, 2002 (“Rules”) as amended from
                      time to time.
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        We{" "}
                        <span className="text-blue-500">{formData?.bank?.name},</span>{" "}
                        having its registered office at{" "}
                        <span className="text-blue-500">
                          ({formData?.bank?.address?.address})
                        </span>{" "}
                        and is engaged inter-alia in the business of rendering
                        finance/loan facilities to the intending borrowers, primarily
                        against the security of asset.
                      </li>
                      <li className="pb-5">
                        You had approached{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},{" "}
                        </span>{" "}
                        requesting the grant of a finance loan to the tune of{" "}
                        <span className="font-sans font-semibold">
                          {formData.amount}/-
                        </span>
                        (
                        <span className="font-semibold">
                          <AmountToWord amount={formData.amount} />
                        </span>
                        ) (“Loan/Facility”) Wherein the{" "}
                        <span className="text-blue-500">Addressee 2 & 3</span> stood
                        as guarantor and mortgagor. (Hereinafter all the above-named
                        Addressees be collectively referred as{" "}
                        <span className="font-semibold">“borrowers”</span>).
                      </li>
                      <li className="pb-5">
                        Pursuant to your specific representation inter-alia with
                        respect to adherence of the terms of repayment of the said
                        loan,{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>{" "}
                        has sanctioned a Loan vide Sanction Letter dated{" "}
                        <input
                          type="date"
                          placeholder="DD/MM/YYYY"
                          name="sanctionDate"
                          id="sanctionDate"
                          value={noticeDetail.sanctionDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.sanctionDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        in favour of you the above named borrowers vide Loan Account
                        Number{" "}
                        <span className="font-semibold font-sans">
                          {formData.identity_number}
                        </span>
                        . Therefore, a{" "}
                        <span className="text-blue-500">
                          Loan Agreement cum Mortgage Deed dated XXXXX (“Loan
                          Agreement”){" "}
                        </span>
                        and other loan related documents were executed to inter-alia
                        create registered mortgage in respect of development rights
                        over the properties as mentioned below in favour of{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        against the said Loan/facility.
                      </li>
                      <li className="pb-5">
                        That for securing the above-mentioned loan, you the Addressees
                        No. 2 & 3, executed{" "}
                        <span className="text-blue-500">
                          Guarantee Agreement dated XXXXXXX{" "}
                        </span>
                        in your capacity as the guarantors and mortgagor thereby
                        guaranteeing joint and/or several personal guarantees for
                        securing the due repayment of the above-mentioned Loan along
                        with interest and other charges payable thereon.
                      </li>
                      <li className="pb-5">
                        The Assets over which security has been created in favour of{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        are hereinafter` collectively referred to as “Secured Asset”.
                      </li>
                      <li className="pb-5">
                        We hereby inform you the above-named addressees, that{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        is the secured creditor, and the debt owing to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>{" "}
                        is a secured debt inter-alia on the said Secured Asset, which
                        is a secured asset of{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        The term borrower has been defined under Section 2(f) of the
                        SARFAESI Act, 2002 and means any person who has been granted
                        financial assistance by Bank/Financial Institution or who has
                        given any guarantee or created any mortgage / created charge
                        as security for the said financial assistance granted by the
                        Bank/Financial Institution. Hence, all and each of the
                        borrowers/guarantors of{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        who are under a liability to{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        have made default in payment of the secured debt including
                        interests in respect thereof.
                      </li>
                      <li className="pb-5">
                        We hereby inform you that you the above-named addressee have
                        committed breach of the terms and conditions of the said Loan
                        Agreement and other documents executed in relation thereto by
                        inter alia defaulting in payment of instalments due and
                        payable by you to{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        under the said Loan Agreement. Despite repeated requests
                        calling upon you to repay the interest due, all of you and
                        each of you who are jointly and severally liable, have failed
                        to repay the outstanding dues.
                      </li>
                      <li className="pb-5">
                        As the Equated Monthly Instalments of the aforesaid Loan
                        Accounts has remained overdue, your Account has been
                        classified as a "Non-Performing Asset (NPA)" on{" "}
                        <input
                          type="date"
                          required
                          placeholder="DD/MM/YYYY"
                          name="nonPerformingDate"
                          id="nonPerformingDate"
                          value={noticeDetail.nonPerformingDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.nonPerformingDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        in the books of accounts maintained by {formData?.bank?.name}{" "}
                        in accordance with the directives and guidelines relating to
                        asset classification, issued by the National Housing Bank/
                        Reserve Bank of India, from time to time.
                      </li>
                      <li className="pb-5">
                        We hereby inform you that, total amount outstanding and
                        payable to{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>{" "}
                        by you the addressees are Rs.{" "}
                        <input
                          type="text"
                          required
                          placeholder="Total Amount Outstanding"
                          name="demandTotalAmount"
                          id="demandTotalAmount"
                          value={noticeDetail.demandTotalAmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.demandTotalAmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                        /- in respect of the aforesaid Facility. The details of the
                        said dues are more specifically mentioned in{" "}
                        <span className="font-semibold">Schedule-l</span> appearing
                        herein under.
                      </li>
                      <li className="pb-5">
                        Whereas you the below mentioned Borrower's, Co-Borrower's,
                        Guarantor's and Mortgagors have availed loans from{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        by mortgaging your immovable properties. Consequent to default
                        committed by you all, your loan account has been classified as
                        Non-performing Asset, whereas{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>{" "}
                        being a secured creditor under the Securitization and
                        Reconstruction of Financial Assets and Enforcement of Security
                        Interest Act, 2002, and in exercise of the powers conferred
                        under section 13(2) of the said Act read with rule 2 of
                        Security Interest (Enforcement) Rules 2002, issued Demand
                        notice calling upon the Borrower's/ Co-Borrower's/
                        Guarantor's/ Mortgagors to repay the amount mentioned in the
                        notices with future interest thereon within 60 days from the
                        date of the present notice.
                      </li>
                      <li className="pb-5">
                        Under the circumstances,{" "}
                        <span className="text-blue-500">{formData?.bank?.name} </span>
                        is taking recourse to the express provisions as contemplated
                        in Section 13(2) of the SARFAESI Act, 2002, and hereby call
                        upon you the addressees, to repay the aforesaid amount of Rs.
                        <input
                          type="text"
                          required
                          placeholder="Total Amount Due"
                          name="totalDueAmount"
                          id="totalDueAmount"
                          value={noticeDetail.totalDueAmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.totalDueAmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                        /- as on{" "}
                        <input
                          type="date"
                          required
                          placeholder="DD/MM/YYYY"
                          name="noticeDate"
                          id="noticeDate"
                          value={noticeDetail.noticeDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.noticeDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                        along with future interest, other cost and charges as set out
                        in the documents executed in connection with the Facility
                        computable till the date of payment in full, within 60 (Sixty)
                        days from the date of this notice, failing which we shall be
                        entitled to exercise any and/or all of the rights, available
                        to a secured creditor and as more specifically stipulated in
                        Sub-Section (4) of Section 13 of the SARFAESI Act, 2002; which
                        will include one or more of the following measures to recover{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>
                        's secured debt, namely: -
                        <ul className="list-disc list-outside ml-20 mt-3">
                          <li className="pb-5">
                            take possession of the secured assets of the borrower
                            including the right to transfer by way of lease,
                            assignment or sale for realizing the secured assets;
                          </li>
                          <li className="pb-5">
                            take over the management of the business of the borrower
                            including the right to transfer by way of lease,
                            assignment or sale for realising the secured asset;
                          </li>
                          <li className="pb-5">
                            appoint any person (hereafter referred to as the manager),
                            to manage the secured assets the possession of which has
                            been taken over by the secured creditor;
                          </li>
                          <li className="pb-5">
                            require at any time by notice in writing, any person who
                            has acquired any of the secured assets from the borrower
                            and from whom any money is due or may become due to the
                            borrower, to pay the secured creditor, so much of the
                            money as is sufficient to pay the secured debt.
                          </li>
                          <li className="pb-5 list-none">
                            Details of the Secured Assets in respect of which{" "}
                            <span className="text-blue-500">
                              {formData?.bank?.name}
                            </span>{" "}
                            shall be constrained to enforce its rights, in the event
                            of nonpayment of the aforesaid amount, within the
                            aforesaid period is more specifically enumerated above.
                          </li>
                        </ul>
                      </li>
                      <li className="pb-5">
                        Notice is therefore given to the Borrower/ Co-Borrower/
                        Guarantor & Mortgagor, calling upon them to make payment of
                        the aggregate amount inclusive interest, against all the
                        respective Borrower/ Co-Borrower within 60 days of the receipt
                        of this notice as the said amount is found payable in relation
                        to the respective loan account as on the date.
                      </li>
                      <li className="pb-5">
                        You are further requested to note that as per Section 13(13)
                        of SARFAESI Act, 2002, you are restrained/prohibited from
                        disposing of or dealing with the above Secured Assets or
                        transferring by way of sale, lease or otherwise (other than in
                        the ordinary course of business) any of the above Secured
                        Assets, without prior written consent of{" "}
                        <span className="text-blue-500">{formData?.bank?.name}.</span>{" "}
                        We may add that non-compliance with the above provision
                        contained in Section 13(13) of SARFAESI Act, 2002 is an
                        offence punishable under Section 29 of the said Act.
                      </li>
                      <li className="pb-5">
                        Please note that your attention is invited to provisions of
                        Sub-Section (8) of Section 13 of the SARFAESI Act, 2002
                        whereunder you can tender/pay the entire amount of outstanding
                        dues together with all costs, charges and expenses incurred by
                        our Company till the date of publication of the notice for
                        sale of the Secured Asset(s) by public auction or by inviting
                        quotations or tender from public or private treaty for
                        transfer by way of lease, assignment or sale of the Secured
                        Asset. Please also note that if the entire amount of
                        outstanding dues together with the costs, charges and expenses
                        incurred by our Company is not tendered before the events as
                        mentioned above, you may not be entitled to redeem the Secured
                        Asset(s) thereafter.
                      </li>
                      <li className="pb-5">
                        Please also note that this notice is sent to you without
                        prejudice to the other rights and remedies available to{" "}
                        <span>{formData?.bank?.name} </span>including initiation of
                        the appropriate legal proceedings before the appropriate
                        courts and/or tribunal for recovery of the above said
                        outstanding amount. This Notice is also without prejudice to{" "}
                        <span className="text-blue-500">{formData?.bank?.name}</span>
                        's right to institute further proceedings as warranted under
                        the law.
                      </li>
                      <li className="pb-5">
                        Any correspondence in this regard may be addressed to the
                        Authorised Officer at the above-mentioned Registered Office at
                        New Delhi.
                      </li>
                      <div className="mt-10">
                        <textarea
                          className="border border-gray-500 p-3"
                          name="additionalText"
                          value={noticeDetail.additionalText}
                          onChange={handleNoticeChange}
                          placeholder="You can write your additional content here............"
                          id="additionalText"
                          cols="80"
                          rows="6"
                        ></textarea>
                      </div>
                    </ul>
                    <div>
                      <h2>
                        You are therefore called upon to comply with the demand under
                        this notice and to avoid further action under the
                        Securitisation and Reconstruction of Financial Assets and
                        Enforcement of Security Interest Act, 2002, read with Security
                        Interest (Enforcement) Rules, 2002 which shall be at your
                        costs and consequences, OF WHICH PLEASE TAKE NOTICE.
                      </h2>
                    </div>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerName"
                        id="authOfficerName"
                        value={noticeDetail.authOfficerName}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Name]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerDesignation"
                        id="authOfficerDesignation"
                        value={noticeDetail.authOfficerDesignation}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Designation]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerContact"
                        id="authOfficerContact"
                        value={noticeDetail.authOfficerContact}
                        onChange={handleNoticeChange}
                        placeholder="[Contact Details of the Authorized Officer]"
                        className={`px-1 w-[55%] border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                  </div>
                  <div className="text-center mt-7">
                    <button
                      type="submit"
                      className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>)}
        </div>
      ) : (
        <div className="font-serif py-10">
          {loading ? (
            <ServeLoading />
          ) : (
            <div className="w-[860px] mx-auto">
              <div className="bg-txtclr py-10 pb-28 px-20">
                <div className="flex justify-end text-gray-500 mt-5 mb-3">
                  <button
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                    className="text-[15px] px-2 text-txtclr bg-black font-sans rounded-sm"
                  >
                    X
                  </button>
                </div>
                <div ref={pdfRef} className="p-4">

                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Reminder Notice dated (
                      <span className="font-semibold text-blue-500">
                        First Reminder Date,{" "}
                      </span>
                      bearing Reference No.:{" "}
                      <span className="text-blue-500 font-semibold">
                        {" "}
                        XXXXX)
                      </span>{" "}
                      for First Month Payment Default, Reminder dated (Second
                      Reminder Date, bearing Reference No. :
                      <span className="font-semibold text-blue-500">
                        {" "}
                        XXXXXX)
                      </span>{" "}
                      for Second Month Payment Default and Reminder dated (
                      <span className="font-semibold text-blue-500">
                        Final Reminder Date,
                      </span>{" "}
                      bearing Reference Number.:{" "}
                      <span className="font-semibold text-blue-500">
                        XXXXX)
                      </span>{" "}
                      for Third Month Payment Default.
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Notice under Section 13(2) of the Securitization and
                      Reconstruction of Financial Assets and Enforcement of
                      Security Interest Act, 2002 (“SARFAESI Act, 2002”) read
                      with Security Interest (Enforcement) Rules, 2002
                      (“Rules”) as amended from time to time.
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        We{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},
                        </span>{" "}
                        having its registered office at{" "}
                        <span className="text-blue-500">
                          ({formData?.bank?.address?.address})
                        </span>{" "}
                        and is engaged inter-alia in the business of rendering
                        finance/loan facilities to the intending borrowers,
                        primarily against the security of asset.
                      </li>
                      <li className="pb-5">
                        You had approached{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},{" "}
                        </span>{" "}
                        requesting the grant of a finance loan to the tune of{" "}
                        <span className="font-sans font-semibold">
                          {formData.amount}/-
                        </span>{" "}
                        (
                        <span className="font-semibold">
                          <AmountToWord amount={formData.amount} />
                        </span>
                        ) (“Loan/Facility”) Wherein the{" "}
                        <span className="text-blue-500">Addressee 2 & 3</span>{" "}
                        stood as guarantor and mortgagor. (Hereinafter all the
                        above-named Addressees be collectively referred as{" "}
                        <span className="font-semibold">“borrowers”</span>).
                      </li>
                      <li className="pb-5">
                        Pursuant to your specific representation inter-alia
                        with respect to adherence of the terms of repayment of
                        the said loan,{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>{" "}
                        has sanctioned a Loan vide Sanction Letter dated{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.sanctionDate}
                        </span>{" "}
                        in favour of you the above named borrowers vide Loan
                        Account Number{" "}
                        <span className="font-semibold font-sans">
                          {formData.identity_number}
                        </span>
                        . Therefore, a{" "}
                        <span className="text-blue-500">
                          Loan Agreement cum Mortgage Deed dated XXXXX (“Loan
                          Agreement”){" "}
                        </span>
                        and other loan related documents were executed to
                        inter-alia create registered mortgage in respect of
                        development rights over the properties as mentioned
                        below in favour of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        against the said Loan/facility.
                      </li>
                      <li className="pb-5">
                        That for securing the above-mentioned loan, you the
                        Addressees No. 2 & 3, executed{" "}
                        <span className="text-blue-500">
                          Guarantee Agreement dated XXXXXXX{" "}
                        </span>
                        in your capacity as the guarantors and mortgagor
                        thereby guaranteeing joint and/or several personal
                        guarantees for securing the due repayment of the
                        above-mentioned Loan along with interest and other
                        charges payable thereon.
                      </li>
                      <li className="pb-5">
                        The Assets over which security has been created in
                        favour of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        are hereinafter` collectively referred to as “Secured
                        Asset”.
                      </li>
                      <li className="pb-5">
                        We hereby inform you the above-named addressees, that{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        is the secured creditor, and the debt owing to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>{" "}
                        is a secured debt inter-alia on the said Secured
                        Asset, which is a secured asset of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        The term borrower has been defined under Section 2(f)
                        of the SARFAESI Act, 2002 and means any person who has
                        been granted financial assistance by Bank/Financial
                        Institution or who has given any guarantee or created
                        any mortgage / created charge as security for the said
                        financial assistance granted by the Bank/Financial
                        Institution. Hence, all and each of the
                        borrowers/guarantors of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        who are under a liability to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        have made default in payment of the secured debt
                        including interests in respect thereof.
                      </li>
                      <li className="pb-5">
                        We hereby inform you that you the above-named
                        addressee have committed breach of the terms and
                        conditions of the said Loan Agreement and other
                        documents executed in relation thereto by inter alia
                        defaulting in payment of instalments due and payable
                        by you to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        under the said Loan Agreement. Despite repeated
                        requests calling upon you to repay the interest due,
                        all of you and each of you who are jointly and
                        severally liable, have failed to repay the outstanding
                        dues.
                      </li>
                      <li className="pb-5">
                        As the Equated Monthly Instalments of the aforesaid
                        Loan Accounts has remained overdue, your Account has
                        been classified as a "Non-Performing Asset (NPA)" on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.nonPerformingDate}
                        </span>{" "}
                        in the books of accounts maintained by (Name of the
                        Bank) in accordance with the directives and guidelines
                        relating to asset classification, issued by the
                        National Housing Bank/ Reserve Bank of India, from
                        time to time.
                      </li>
                      <li className="pb-5">
                        We hereby inform you that, total amount outstanding
                        and payable to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>{" "}
                        by you the addressees are Rs.{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.demandTotalAmount}/-{" "}
                        </span>
                        <span className="font-semibold">
                          (
                          <AmountToWord
                            amount={noticeDetail.demandTotalAmount}
                          />
                          )
                        </span>{" "}
                        in respect of the aforesaid Facility. The details of
                        the said dues are more specifically mentioned in{" "}
                        <span className="font-semibold">Schedule-l</span>{" "}
                        appearing herein under.
                      </li>
                      <li className="pb-5">
                        Whereas you the below mentioned Borrower's,
                        Co-Borrower's, Guarantor's and Mortgagors have availed
                        loans from{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        by mortgaging your immovable properties. Consequent to
                        default committed by you all, your loan account has
                        been classified as Non-performing Asset, whereas{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>{" "}
                        being a secured creditor under the Securitization and
                        Reconstruction of Financial Assets and Enforcement of
                        Security Interest Act, 2002, and in exercise of the
                        powers conferred under section 13(2) of the said Act
                        read with rule 2 of Security Interest (Enforcement)
                        Rules 2002, issued Demand notice calling upon the
                        Borrower's/ Co-Borrower's/ Guarantor's/ Mortgagors to
                        repay the amount mentioned in the notices with future
                        interest thereon within 60 days from the date of the
                        present notice.
                      </li>
                      <li className="pb-5">
                        Under the circumstances,{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        is taking recourse to the express provisions as
                        contemplated in Section 13(2) of the SARFAESI Act,
                        2002, and hereby call upon you the addressees, to
                        repay the aforesaid amount of Rs.{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.totalDueAmount}/-{" "}
                        </span>
                        <span className="font-semibold">
                          (
                          <AmountToWord
                            amount={noticeDetail.totalDueAmount}
                          />
                          )
                        </span>{" "}
                        as on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.noticeDate}
                        </span>{" "}
                        along with future interest, other cost and charges as
                        set out in the documents executed in connection with
                        the Facility computable till the date of payment in
                        full, within 60 (Sixty) days from the date of this
                        notice, failing which we shall be entitled to exercise
                        any and/or all of the rights, available to a secured
                        creditor and as more specifically stipulated in
                        Sub-Section (4) of Section 13 of the SARFAESI Act,
                        2002; which will include one or more of the following
                        measures to recover{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>
                        's secured debt, namely: -
                        <ul className="list-disc list-outside ml-20 mt-3">
                          <li className="pb-5">
                            take possession of the secured assets of the
                            borrower including the right to transfer by way of
                            lease, assignment or sale for realizing the
                            secured assets;
                          </li>
                          <li className="pb-5">
                            take over the management of the business of the
                            borrower including the right to transfer by way of
                            lease, assignment or sale for realising the
                            secured asset;
                          </li>
                          <li className="pb-5">
                            appoint any person (hereafter referred to as the
                            manager), to manage the secured assets the
                            possession of which has been taken over by the
                            secured creditor;
                          </li>
                          <li className="pb-5">
                            require at any time by notice in writing, any
                            person who has acquired any of the secured assets
                            from the borrower and from whom any money is due
                            or may become due to the borrower, to pay the
                            secured creditor, so much of the money as is
                            sufficient to pay the secured debt.
                          </li>
                          <li className="pb-5 list-none">
                            Details of the Secured Assets in respect of which{" "}
                            <span className="text-blue-500">
                              {formData?.bank?.name}
                            </span>{" "}
                            shall be constrained to enforce its rights, in the
                            event of nonpayment of the aforesaid amount,
                            within the aforesaid period is more specifically
                            enumerated above.
                          </li>
                        </ul>
                      </li>
                      <li className="pb-5">
                        Notice is therefore given to the Borrower/
                        Co-Borrower/ Guarantor & Mortgagor, calling upon them
                        to make payment of the aggregate amount inclusive
                        interest, against all the respective Borrower/
                        Co-Borrower within 60 days of the receipt of this
                        notice as the said amount is found payable in relation
                        to the respective loan account as on the date.
                      </li>
                      <li className="pb-5">
                        You are further requested to note that as per Section
                        13(13) of SARFAESI Act, 2002, you are
                        restrained/prohibited from disposing of or dealing
                        with the above Secured Assets or transferring by way
                        of sale, lease or otherwise (other than in the
                        ordinary course of business) any of the above Secured
                        Assets, without prior written consent of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}.
                        </span>{" "}
                        We may add that non-compliance with the above
                        provision contained in Section 13(13) of SARFAESI Act,
                        2002 is an offence punishable under Section 29 of the
                        said Act.
                      </li>
                      <li className="pb-5">
                        Please note that your attention is invited to
                        provisions of Sub-Section (8) of Section 13 of the
                        SARFAESI Act, 2002 whereunder you can tender/pay the
                        entire amount of outstanding dues together with all
                        costs, charges and expenses incurred by our Company
                        till the date of publication of the notice for sale of
                        the Secured Asset(s) by public auction or by inviting
                        quotations or tender from public or private treaty for
                        transfer by way of lease, assignment or sale of the
                        Secured Asset. Please also note that if the entire
                        amount of outstanding dues together with the costs,
                        charges and expenses incurred by our Company is not
                        tendered before the events as mentioned above, you may
                        not be entitled to redeem the Secured Asset(s)
                        thereafter.
                      </li>
                      <li className="pb-5">
                        Please also note that this notice is sent to you
                        without prejudice to the other rights and remedies
                        available to <span>{formData?.bank?.name} </span>
                        including initiation of the appropriate legal
                        proceedings before the appropriate courts and/or
                        tribunal for recovery of the above said outstanding
                        amount. This Notice is also without prejudice to{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}
                        </span>
                        's right to institute further proceedings as warranted
                        under the law.
                      </li>
                      <li className="pb-5">
                        Any correspondence in this regard may be addressed to
                        the Authorised Officer at the above-mentioned
                        Registered Office at New Delhi.
                      </li>
                      <li className="pb-5">{noticeDetail.additionalText}</li>
                    </ul>
                    <div>
                      <h2>
                        You are therefore called upon to comply with the
                        demand under this notice and to avoid further action
                        under the Securitisation and Reconstruction of
                        Financial Assets and Enforcement of Security Interest
                        Act, 2002, read with Security Interest (Enforcement)
                        Rules, 2002 which shall be at your costs and
                        consequences, OF WHICH PLEASE TAKE NOTICE.
                      </h2>
                    </div>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-16">
                  <button
                    type="submit"
                    onClick={generatePdfAndSend}
                    className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DemandNotice;
